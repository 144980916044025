<template>
  <v-dialog
    v-if="initialized"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="true"
    persistent
    sentry-tag="profile-dialog"
    width="600px"
  >
    <v-sheet
      v-if="welcomeVideo.enabled && showWelcomeVideo"
      :height="$vuetify.breakpoint.smAndDown ? '100%' : undefined"
      class="d-flex flex-column text-center pt-6 pb-8 px-4"
    >
      <h3 class="mb-4">{{ $t('welcomeVideo.title') }}</h3>

      <m-video :video="video" :focused-video="focusedVideo" @onFocus="focusVideo = true"></m-video>

      <h2 class="text--text mt-6 mb-4">{{ heading }}</h2>
      <p class="text--text text--lighten-5 mb-6 keep-new-line">{{ $t('welcomeVideo.text') }}</p>
      <div class="d-flex justify-center">
        <v-btn color="button" depressed @click="closeWelcomeVideo">{{ $t('welcomeVideo.button') }}</v-btn>
      </div>
    </v-sheet>

    <v-sheet v-else class="pa-12">
      <validation-observer v-slot="{ invalid }" class="d-flex flex-column m-profile-dialog-container">
        <div class="text-center">
          <h2 class="text--text mb-4">{{ $t('profileForm.title') }}</h2>
          <div class="text--text text--lighten-5 mb-6">
            {{ $t('profileForm.description') }}
          </div>
        </div>
        <m-text-field
          v-model="identity.firstName"
          :label="$t('profile.personal.firstName')"
          class="mb-3"
          sentry-tag="profile.input.firstName"
          validation-rules="required|max:150"
        />
        <m-text-field
          v-model="identity.lastName"
          :label="$t('profile.personal.lastName')"
          class="mb-3"
          sentry-tag="profile.input.lastName"
          validation-rules="required|max:150"
        />
        <m-text-field
          v-model="identity.email"
          :label="$t('profile.personal.email')"
          class="mb-3"
          readonly
          sentry-tag="profile.input.email"
          validation-rules="required|email"
        />
        <m-select
          v-if="hasCustomCompanies"
          v-model="personal.company"
          :items="customCompanies"
          :label="$t('profile.personal.company')"
          class="mb-3"
        />
        <m-text-field
          v-else
          v-model="personal.company"
          :label="$t('profile.personal.company')"
          class="mb-3"
          sentry-tag="profile.input.company"
          validation-rules="max:150"
        />
        <m-select
          v-if="hasCustomUnits"
          v-model="personal.unit"
          :items="customUnits"
          :label="$t('profile.personal.unit')"
          class="mb-3"
        />
        <m-select
          v-if="hasCustomPositions"
          v-model="personal.jobTitle"
          :items="customPositions"
          :label="$t('profile.personal.jobTitle')"
          class="mb-3"
        />
        <m-text-field
          v-else
          v-model="personal.jobTitle"
          :label="$t('profile.personal.jobTitle')"
          class="mb-3"
          sentry-tag="profile.input.jobTitle"
          validation-rules="max:150"
        />
        <m-location
          v-model="personal.location"
          :label="$t('profile.personal.location')"
          class="mb-4"
          sentry-tag="profile.input.location"
        />

        <template v-if="canSelectLocale">
          <m-select-ui-locale
            v-if="canSelectLocale"
            v-model="lang"
            :label="$t('profileForm.lang')"
            class="mb-4"
            sentry-tag="profile.input.lang"
          />
        </template>

        <template v-if="!forceTimeZone">
          <m-time-zone-picker
            v-model="tz"
            :label="$t('profile.tz')"
            class="mb-4"
            sentry-tag="profile.tz"
          ></m-time-zone-picker>
        </template>
        <div class="d-flex justify-center">
          <v-btn :disabled="invalid" color="button" depressed @click="saveUserData">
            {{ $t('profileForm.buttonNext') }}
          </v-btn>
        </div>
      </validation-observer>
    </v-sheet>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { MLocation, MSelectUiLocale, MTextField, MTimeZonePicker } from '@/components/Inputs';
import { GetMe, SaveUserProfile, UsersState } from '@/store/users';
import { mapGetters, mapState } from 'vuex';
import { RouteName } from '@/router/types';
import { TenantState } from '@/store/tenant';
import { IVideo, VideoType, WelcomeVideoConfig } from '@mentessa/types';
import { ValidationObserver } from 'vee-validate';
import MVideo from '@/components/MVideo/MVideo.vue';
import MSelect from '@/components/Inputs/MSelect/MSelect.vue';

export default Vue.extend({
  name: 'm-profile-dialog',
  components: {
    MSelect,
    MVideo,
    ValidationObserver,
    MTimeZonePicker,
    MSelectUiLocale,
    MLocation,
    MTextField,
  },
  data: () => ({
    identity: {
      firstName: '',
      lastName: '',
      email: '',
    },
    personal: {
      company: '',
      jobTitle: '',
      location: '',
      unit: undefined,
      languages: [],
    },
    tags: {
      expertise: [],
      interests: [],
    },
    lang: undefined,
    tz: undefined,
    showWelcomeVideo: true,
    showPreviewImage: true,
    initialized: false,
    focusVideo: false,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TenantState>('tenant', {
      welcomeVideo: (state: TenantState): WelcomeVideoConfig =>
        state.tenant?.attributes.welcomeVideo ?? { enabled: false },
      forceTimeZone: (state: TenantState) => state.tenant?.attributes?.forceTenantTimeZone ?? false,
      forcedTz: (state: TenantState) => state.tenant?.tz,
      useFullProfile: (state: TenantState) => state.tenant?.attributes.useFullProfile ?? false,
      tenantName: (state: TenantState) => state.tenant?.attributes?.theme?.title ?? '',
      customPositions: (state: TenantState) => state.tenant?.attributes?.positions ?? [],
      customUnits: (state: TenantState) => state.tenant?.attributes?.units ?? [],
      customCompanies: (state: TenantState) => state.tenant?.attributes?.companies ?? [],
    }),
    ...mapGetters('tenant', {
      availableLocales: 'availableLocales',
      defaultLocale: 'defaultLocale',
      isCommunityRulesAvailable: 'isCommunityRulesAvailable',
      isCommunityRulesRequired: 'isCommunityRulesRequired',
    }),
    hasCustomPositions() {
      return this.customPositions?.length > 0;
    },
    hasCustomUnits() {
      return this.customUnits?.length > 0;
    },
    hasCustomCompanies() {
      return this.customCompanies?.length > 0;
    },
    canSelectLocale() {
      return this.availableLocales.length > 1;
    },
    video(): IVideo {
      return {
        id: 0,
        type: this.welcomeVideo.type ?? VideoType.Raw,
        url: this.welcomeVideo.link,
        attributes: { preview: this.welcomeVideo.preview, tags: [] },
      };
    },
    focusedVideo() {
      return this.focusVideo ? this.video : undefined;
    },
    heading() {
      return this.$t('welcomeVideo.heading', { tenant: this.tenantName });
    },
  },
  async mounted() {
    this.focusVideo = false;
    if (this.me.complete) {
      if (window.history.state) {
        this.$router.back();
      } else {
        await this.$router.push({ name: RouteName.Community });
      }
    } else {
      this.updateData();
      this.initialized = true;
    }
  },
  methods: {
    async saveUserData() {
      const dto = {
        attributes: {
          ...this.personal,
        },
        identity: {
          attributes: {
            ...this.identity,
          },
        },
        tags: this.tags,
      };
      if (this.lang) {
        dto['lang'] = this.lang;
      }
      if (this.tz) {
        dto['tz'] = this.tz;
      }
      await this.$store.dispatch(new SaveUserProfile(dto));
      await this.$store.dispatch(new GetMe());

      if (this.me.complete) {
        if (window.history.state) {
          this.$router.back();
        } else {
          await this.$router.push({ name: RouteName.Profile });
        }
      }
    },
    updateData() {
      this.identity = {
        firstName: this.me.identity.attributes.firstName,
        lastName: this.me.identity.attributes.lastName,
        email: this.me.identity.attributes.email,
      };
      this.personal = {
        company: this.me.attributes.company,
        jobTitle: this.me.attributes.jobTitle,
        location: this.me.attributes.location,
        languages: this.me.attributes.languages ?? [],
        unit: this.me.attributes.unit,
      };
      this.tags = {
        expertise: this.me.expertiseTags,
        interests: this.me.interestTags,
      };
      this.lang = this.me.locale ?? this.me.attributes.lang ?? this.defaultLocale;
      if (this.forceTimeZone) {
        this.tz = this.forcedTz;
      } else {
        this.tz = this.me.attributes.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'CET';
      }
    },
    async closeWelcomeVideo() {
      if (this.me.complete) {
        if (window.history.state) {
          this.$router.back();
        } else {
          await this.$router.push({ name: RouteName.Community });
        }
      } else if (this.useFullProfile) {
        await this.$router.push({ name: RouteName.Profile });
      } else {
        this.showWelcomeVideo = false;
      }
    },
  },
});
</script>
