import { render, staticRenderFns } from "./MEditNewsDialog.vue?vue&type=template&id=734cefee&scoped=true"
import script from "./MEditNewsDialog.vue?vue&type=script&lang=ts"
export * from "./MEditNewsDialog.vue?vue&type=script&lang=ts"
import style0 from "./MEditNewsDialog.vue?vue&type=style&index=0&id=734cefee&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_prettier@2.8.8_vue-template-compiler@2.7.6_webpack@5.93.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734cefee",
  null
  
)

export default component.exports