<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="true"
    persistent
    sentry-tag="feedback-dialog"
    width="746px"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card v-if="expired" class="ma-0">
      <v-icon class="float-end ma-4" @click="closeDialog">mdi-close</v-icon>
      <v-card-title class="pt-10">
        <div class="d-flex">
          <v-img class="page-title" contain height="40" src="@/assets/feedback/thumb-up.svg" />
          <div class="pt-1 pl-3 mt-2 mb-0">
            <h1 class="page-title mb-3">{{ $t('feedback.title') }}</h1>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <div v-html="expiredMessage" class="ma-4" />
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title class="pt-10">
        <div class="d-flex">
          <v-img class="page-title" contain height="40" src="@/assets/feedback/thumb-up.svg" />
          <div class="pt-1 pl-3 mt-2 mb-0">
            <h1 class="page-title mb-3">{{ $t('feedback.title') }}</h1>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-divider class="mt-4" />
        <div v-if="questions">
          <div v-for="(question, index) in questions" :key="index" class="pt-6">
            <component
              :is="question.component"
              v-if="question.component"
              v-model="answers[question.name]"
              v-bind="question.props"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="px-6 pb-6">
        <v-btn class="ml-auto px-6" color="primary" @click="sendAnswers">{{ $t('feedback.submit') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { MRatingWithText, MTextArea } from '@/components/Inputs';
import { IQuestion, QuestionType } from '@mentessa/types';
import { LoadMentoringSessionFeedbackQuestionnaire, SendMentoringSessionFeedbackAnswers } from '@/store/mentoring';
import { RouteName } from '@/router/types';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

function getQuestionComponent(question: IQuestion) {
  switch (question.type) {
    case QuestionType.RatingWithText:
      return MRatingWithText;
    case QuestionType.FreeText:
      return MTextArea;
  }
  return undefined;
}

export default Vue.extend({
  name: 'm-feedback-dialog',
  components: { MRatingWithText },
  props: {
    sessionId: Number,
  },
  data: () => ({
    sessionFeedback: undefined,
    answers: {},
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    section() {
      return this.sessionFeedback?.questionnaire?.sections?.[0] ?? {};
    },
    questions() {
      return this.section?.questions?.map((question) => {
        const props = {};
        props['label'] = this.tr(`${this.section.name}.questions.${question.name}`);
        if (question.type === QuestionType.RatingWithText) {
          props['commentLabel'] = this.tr(`${this.section.name}.comments.${question.name}`);
          props['max'] = question.max;
          props['boundaries'] = [
            this.tr(`${this.section.name}.boundaries.${question.name}.0`),
            this.tr(`${this.section.name}.boundaries.${question.name}.1`),
          ];
        }
        return {
          ...question,
          component: getQuestionComponent(question),
          props,
        };
      });
    },
    i18nPath() {
      return (
        this.sessionFeedback?.attributes?.i18nPath ??
        this.sessionFeedback?.questionnaire?.attributes?.i18nPath ??
        `questionnaire.${this.sessionFeedback?.questionnaire?.title}`
      );
    },
    tr() {
      return (text: string) => {
        return this.$t(`${this.i18nPath}.${text}`);
      };
    },
    expired() {
      // ToDo: Sync that logic with data from backend.
      const currentDate = new Date().getDate();
      return currentDate > 15;
    },
    expiredMessage() {
      return this.$marked(
        this.$t('feedback.expired', {
          tenant: this.tenant.attributes.theme.title ?? '',
          ritual: this.sessionFeedback?.ritual?.attributes?.title ?? '',
        }),
      );
    },
  },
  async mounted() {
    await this.loadQuestions();
  },
  methods: {
    async sendAnswers() {
      const answersToSend = {};
      Object.entries(this.answers).forEach(([key, options]) => {
        if (Object.values(options).some((v) => v != null)) {
          answersToSend[key] = options;
        }
      });
      try {
        await this.$store.dispatch(
          new SendMentoringSessionFeedbackAnswers({ id: this.sessionId }, this.sessionFeedback, answersToSend),
        );
      } finally {
        await this.closeDialog();
      }
    },
    async loadQuestions() {
      this.sessionFeedback = await this.$store.dispatch(
        new LoadMentoringSessionFeedbackQuestionnaire({ id: this.sessionId }),
      );
      if (!this.sessionFeedback?.questionnaire?.sections?.[0]?.questions?.length) {
        await this.closeDialog();
      }
    },
    async closeDialog() {
      await this.$router.replace({
        name: RouteName.ActiveMentoringSession,
        params: this.$route.params,
        query: this.$route.query,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.m-feedback-description {
  font-size: 14px;
  line-height: 21px;
}
</style>
