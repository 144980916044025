<template>
  <div class="d-flex flex-column text-center pa-4 gap-4">
    <v-row class="mb-0" :class="{ 'gap-4': $vuetify.breakpoint.xsOnly }">
      <v-col class="py-0" cols="12" sm="6">
        <m-text-field
          v-model="firstName"
          :label="$t('profile.personal.firstName')"
          sentry-tag="profile.input.firstName"
          validation-rules="required|max:150"
        />
      </v-col>
      <v-col class="py-0" cols="12" sm="6">
        <m-text-field
          v-model="lastName"
          :label="$t('profile.personal.lastName')"
          sentry-tag="profile.input.lastName"
          validation-rules="required|max:150"
        />
      </v-col>
    </v-row>

    <m-text-field
      v-if="false"
      v-model="email"
      :label="$t('profile.personal.email')"
      readonly
      sentry-tag="profile.input.email"
      validation-rules="required|email"
    />
    <m-select
      v-if="hasCustomPositions"
      v-model="jobTitle"
      :items="customPositions"
      :label="$t('profile.personal.jobTitle')"
    />
    <m-text-field
      v-else
      v-model="jobTitle"
      :label="$t('profile.personal.jobTitle')"
      sentry-tag="profile.input.jobTitle"
      validation-rules="max:150"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { IUser } from '@mentessa/types';
import { MSelect, MTextField } from '@/components/Inputs';
import { mapState } from 'vuex';
import { TenantState } from '@/store/tenant';

export default Vue.extend({
  name: 'm-onboarding-personal-form',
  components: {
    MSelect,
    MTextField,
  },
  props: {
    value: Object as PropType<IUser>,
  },
  computed: {
    ...mapState<TenantState>('tenant', {
      customPositions: (state: TenantState) => state.tenant?.attributes?.positions ?? [],
    }),
    hasCustomPositions() {
      return this.customPositions?.length > 0;
    },
    firstName: {
      get() {
        return this.value?.identity?.attributes.firstName;
      },
      set(value: string) {
        this.updateIdentityAttribute('firstName', value);
      },
    },
    lastName: {
      get() {
        return this.value?.identity?.attributes.lastName;
      },
      set(value: string) {
        this.updateIdentityAttribute('lastName', value);
      },
    },
    email: {
      get() {
        return this.value?.identity?.attributes.email;
      },
      set(value: string) {
        this.updateIdentityAttribute('email', value);
      },
    },
    jobTitle: {
      get() {
        return this.value?.attributes.jobTitle;
      },
      set(value: string) {
        this.updateUserAttribute('jobTitle', value);
      },
    },
  },
  async mounted() {
    this.focusVideo = false;
  },
  methods: {
    updateIdentityAttribute(attr: string, value: string) {
      this.$emit('input', {
        ...this.value,
        identity: {
          ...this.value?.identity,
          attributes: {
            ...this.value?.identity?.attributes,
            [attr]: value,
          },
        },
      });
    },
    updateUserAttribute(attr: string, value: string) {
      this.$emit('input', {
        ...this.value,
        attributes: {
          ...this.value?.identity?.attributes,
          [attr]: value,
        },
      });
    },
  },
});
</script>
