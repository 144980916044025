<template>
  <div class="user-details-wrapper">
    <div class="content mr-4">
      <div v-if="user.attributes.unit" class="group mt-4 mb-10">
        <p class="mb-4 font-weight-bold text-uppercase">{{ $t('profile.personal.unit') }}</p>
        <v-chip class="my-1 mr-1" outlined>{{ user.attributes.unit }}</v-chip>
      </div>
      <div v-if="roles.length" class="group mt-4 mb-10">
        <p class="mb-4 font-weight-bold text-uppercase">{{ $t('userProfileModal.roles') }}</p>
        <v-chip v-for="(role, roleIndex) in roles" :key="roleIndex" class="my-1 mr-1" outlined
          >{{ roleName(role) }}
        </v-chip>
      </div>
      <div v-if="user.attributes.about?.length" class="group mt-4 mb-10">
        <p class="font-weight-bold text-uppercase">{{ $t('userProfileModal.aboutTitle') }}</p>
        <p class="mt-4">{{ user.attributes.about }}</p>
      </div>
      <div v-if="user.expertiseTags.length" class="group mb-10">
        <p class="font-weight-bold text-uppercase">{{ $t('profile.tags.expertise') }}</p>
        <div>
          <v-chip v-for="item in user.expertiseTags" :key="item.id" class="my-1 mr-1" outlined>{{ item.name }}</v-chip>
        </div>
      </div>
      <div v-if="user.interestTags.length" class="group mb-10">
        <p class="font-weight-bold text-uppercase">{{ $t('profile.tags.interests') }}</p>
        <div>
          <v-chip v-for="item in user.interestTags" :key="item.id" class="my-1 mr-1" outlined>{{ item.name }}</v-chip>
        </div>
      </div>

      <div v-for="(field, index) in customLists" :key="`list-item-${index}`" class="group mb-10">
        <template v-if="field.value?.length">
          <p class="font-weight-bold text-uppercase">{{ $t(`profile.custom.${field.name}.title`) }}</p>
          <div v-if="field.multiple">
            <v-chip v-for="(item, index) in field.value" :key="`item-${index}`" class="my-1 mr-1" outlined>
              {{ ti(field.name, item) }}
            </v-chip>
          </div>
          <a v-else-if="field.link" :href="field.value">
            <p class="mt-4 ml-3">{{ field.value }}</p>
          </a>
          <p v-else-if="field.value" class="mt-4 ml-3">
            {{ ti(field.name, field.value) }}
          </p>
        </template>
      </div>
    </div>
    <m-book-time-slot-dialog v-model="showBookingDialog" :user="user" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { FieldType, UserEntity } from '@mentessa/types';
import { TenantState } from '@/store/tenant';
import MBookTimeSlotDialog from '@/components/Dialogs/MBookTimeSlotDialog';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'm-user-details-form',
  props: {
    user: Object as PropType<UserEntity>,
  },
  components: {
    MBookTimeSlotDialog,
  },
  data: () => ({
    showBookingDialog: false,
    selectedSlot: undefined,
  }),
  computed: {
    ...mapGetters('tenant', {
      hiddenRoles: 'hiddenRoles',
    }),
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ti() {
      return (field: string, key: string) => {
        const path = `profile.custom.${field}.items.${key}`;
        if (this.$te(path)) {
          return this.$t(path);
        }

        return key;
      };
    },
    customLists() {
      const result = [];
      for (const field of this.tenant.attributes?.customFields ?? []) {
        const isMultiple = field.type === FieldType.SelectMultiple || field.multiple;
        if (field.hide !== true) {
          result.push({
            name: field.name,
            multiple: isMultiple,
            link: field.type === FieldType.URL,
            value: this.user.attributes?.custom?.[field.name] ?? (isMultiple ? [] : ''),
          });
        }
      }
      return result;
    },
    roles() {
      return this.user?.attributes.roles?.filter((role) => !this.hiddenRoles.includes(role)) ?? [];
    },
    roleName() {
      return (role: string) => {
        if (this.$te(`user.roles.${role}`)) {
          return this.$t(`user.roles.${role}`);
        }
        return role;
      };
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.user-details-wrapper {
  display: flex;
  flex-grow: 1;
  max-width: 100%;

  .content {
    width: 100%;
    max-width: 100%;
  }

  .white-box {
    background: var(--v-white-base);
    border: 1px solid #e7e5e4;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
    padding: 32px 40px 40px;

    & > div {
      padding-top: 10px;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .user-details-wrapper {
    flex-direction: column;
  }
}
</style>
